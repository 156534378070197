import * as services from '@/services'

const defaultFilters = Object.freeze({
  teamName: '',
  isSubteams: null,
  isMembers: null,

  orderBy: 'createdAt',
  isSortAscending: false
})

const applyFilters = (list, filters) => {
  const { teamName, isSubteams, isMembers } = filters

  let filteredList = structuredClone(list)

  if (teamName) {
    filteredList = filteredList.filter(item =>
      item.name.toLowerCase().includes(teamName.toLowerCase())
    )
  }

  if (isSubteams !== null) {
    filteredList = filteredList.filter(item =>
      isSubteams ? item.groupCount : !item.groupCount
    )
  }

  if (isMembers !== null) {
    filteredList = filteredList.filter(item =>
      isMembers ? item.memberCount : !item.memberCount
    )
  }

  return filteredList
}

const applySorting = (list, filters) => {
  const { orderBy, isSortAscending } = filters

  let sortedList = structuredClone(list)

  if (orderBy === 'name') {
    sortedList.sort((a, b) => {
      return isSortAscending
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name)
    })
  }

  if (orderBy === 'total-subteams') {
    sortedList.sort((a, b) =>
      isSortAscending
        ? a.groupCount - b.groupCount
        : b.groupCount - a.groupCount
    )
  }

  if (orderBy === 'total-members') {
    sortedList.sort((a, b) =>
      isSortAscending
        ? a.memberCount - b.memberCount
        : b.memberCount - a.memberCount
    )
  }

  if (orderBy === 'createdAt') {
    sortedList.sort((a, b) =>
      isSortAscending
        ? new Date(a.createdAt) - new Date(b.createdAt)
        : new Date(b.createdAt) - new Date(a.createdAt)
    )
  }

  return sortedList
}

export default {
  namespaced: true,

  state: {
    catalog: {
      isLoading: false,

      rows: {
        initialList: [],
        list: [],
        selected: []
      },

      filters: structuredClone(defaultFilters),
      defaultFilters: structuredClone(defaultFilters)
    }
  },

  actions: {
    async FETCH_ALL({ commit, state }) {
      const filters = state.catalog.filters

      await Promise.all([
        commit('SET_IS_LOADING', true),
        commit('SET_DEFAULT_FILTERS', filters),
        commit('SET_ROWS_INITIAL_LIST', []),
        commit('SET_ROWS_LIST', []),
        commit('SET_ROWS_SELECTED', [])
      ])

      let { list } = await services.teams.fetchAllViews()
      list = applyFilters(list, filters)
      list = applySorting(list, filters)

      await Promise.all([
        commit('SET_ROWS_INITIAL_LIST', list),
        commit('SET_ROWS_LIST', list),
        commit('SET_IS_LOADING', false)
      ])
    },

    async APPLY_FILTERS({ commit, state }) {
      const filters = state.catalog.filters

      await Promise.all([
        commit('SET_DEFAULT_FILTERS', filters),
        commit('SET_ROWS_SELECTED', [])
      ])

      let list = state.catalog.rows.initialList

      list = applyFilters(list, filters)
      list = applySorting(list, filters)

      await Promise.all([commit('SET_ROWS_LIST', list)])
    }
  },

  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.catalog.isLoading = isLoading
    },

    SET_ROWS_INITIAL_LIST(state, list) {
      state.catalog.rows.initialList = list
    },
    SET_ROWS_LIST(state, list) {
      state.catalog.rows.list = list
    },
    SET_ROWS_SELECTED(state, selected) {
      state.catalog.rows.selected = selected
    },

    UPDATE_FILTERS(state, toUpdate) {
      state.catalog.filters = { ...state.catalog.filters, ...toUpdate }
    },

    SET_FILTERS(state, filters) {
      state.catalog.filters = filters
    },
    RESET_FILTERS(state) {
      state.catalog.filters = structuredClone(defaultFilters)
      state.catalog.defaultFilters = structuredClone(defaultFilters)
    },

    SET_DEFAULT_FILTERS(state, defaultFilters) {
      state.catalog.defaultFilters = structuredClone(defaultFilters)
    },

    SET_FILTERS_ORDER_BY(state, orderBy) {
      state.catalog.filters.orderBy = orderBy
    },
    SET_FILTERS_IS_SORT_ASCENDING(state, isSortAscending) {
      state.catalog.filters.isSortAscending = isSortAscending
    }
  }
}
