import axios from 'axios'

import router from '@/router'
import * as routeUtils from '@/utils/route'

import store from '@/store'

const SUCCESS_RESPONSE = { status: 200, data: {} }

const logout = () => {
  store.dispatch('auth/LOGOUT', {}, { root: true })
}

let requestsToRefresh = []
let isRefreshRequesting = false

const onError401 = error => {
  const { config } = error

  const accessToken = store.state.auth.accessToken

  if (!accessToken) {
    logout()
    return Promise.resolve(SUCCESS_RESPONSE)
  }

  if (!isRefreshRequesting) {
    isRefreshRequesting = true

    axios
      .post('/api/user/refresh-token')
      .then(({ content, error }) => {
        if (error) {
          logout()
          return Promise.resolve(SUCCESS_RESPONSE)
        }

        const { token: accessToken, refreshTokenExpiresAt } = content

        // store.commit(
        //   'auth/SET_REFRESH_TOKEN_EXPIRES_AT',
        //   refreshTokenExpiresAt,
        //   { root: true }
        // )
        store.commit(
          'auth/SET_ACCESS_TOKEN',
          { accessToken, refreshTokenExpiresAt },
          { root: true }
        )
        requestsToRefresh.forEach(cb => cb(accessToken))
      })
      .catch(logout)
      .finally(() => {
        requestsToRefresh = []
        isRefreshRequesting = false
      })
  }

  return new Promise(resolve => {
    requestsToRefresh.push(accessToken => {
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
        resolve(axios(config))
      }

      resolve(SUCCESS_RESPONSE)
    })
  })
}

const request = {
  onBeforeSent: config => {
    const accessToken = store.state.auth.accessToken
    const isFormData = config.data instanceof FormData
    const isAuthorized = store.getters['auth/isAuthorized']
    const isPrincipalPage = routeUtils.defineIsPrincipalPage(
      router.currentRoute
    )

    const tenantIdListFromHeader = config.headers?.ScopedTenants
    const tenantIdListFromStore = (() => {
      const tenantIdList = (
        isAuthorized
          ? isPrincipalPage
            ? [store.state.tenants?.tenants?.selected?.id]
            : [...(store.state.admin?.tenants?.tenants?.selected || [])].map(
                tenant => tenant?.id
              )
          : []
      ).filter(Boolean)

      return tenantIdList.length > 0 ? tenantIdList : null
    })()
    const tenantFromPath = store.getters['tenants/getTenantByMarker'](
      router.currentRoute?.params?.tenantMarker
    )
    const tenantIdListFromPath = [tenantFromPath?.id].filter(Boolean)

    const tenantIdList =
      tenantIdListFromHeader ||
      tenantIdListFromStore ||
      tenantIdListFromPath ||
      []
    const isTenantIdList = Boolean(tenantIdList.length)

    const headers = {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...(isFormData && { 'Content-Type': 'multipart/form-data' }),
      ...(isTenantIdList && { ScopedTenants: tenantIdList }),
      'Access-Control-Allow-Origin': '*'
    }

    config.headers = { ...config.headers, ...headers }

    return config
  },
  onError: Promise.reject
}

const response = {
  onSuccess: response => {
    if (response?.data instanceof Blob) {
      return response
    }

    const data = response?.data || {}

    if (response?.status === 200) {
      const { error } = data

      delete data?.result
      delete data?.error

      return { isSuccess: !error, error, ...data }
    }

    const { data: { message, error } = {}, statusText } = response || {}
    const errorText = message || error || statusText || 'Unknown error'

    return { isSuccess: false, error: errorText }
  },
  onError: error => {
    const { response } = error

    if (response?.status === 401) {
      return onError401(error)
    } else {
      return Promise.reject(new Error(error))
    }
  }
}

axios.defaults.baseURL = import.meta.env.VITE_VUE_APP_API_URL
axios.defaults.withCredentials = true

axios.interceptors.request.use(request.onBeforeSent, request.onError)
axios.interceptors.response.use(response.onSuccess, response.onError)
