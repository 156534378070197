import { wireService } from '@/utils/signalr'

const TWO_MINUTES = 120000

export default (...args) => {
  const events = args
  const isEvents = Boolean(events?.length)

  return {
    data: () => ({
      _signalrInterval: null
    }),

    mounted() {
      if (isEvents) {
        events.forEach(([{ clientEventName }, eventHandlerName]) => {
          window.addEventListener(clientEventName, this[eventHandlerName])
        })
      }
    },

    beforeDestroy() {
      if (isEvents) {
        events.forEach(([{ clientEventName }, eventHandlerName]) => {
          window.removeEventListener(clientEventName, this[eventHandlerName])
        })
      }

      this.$signalr_stopServerMethodWithInterval()
    },

    methods: {
      async $signalr_callServerMethodWithInterval({
        name,
        runImmediately,
        payload,
        duration = TWO_MINUTES
      }) {
        if (runImmediately) {
          await this._handleServerCall({ name, payload })
        }

        this._signalrInterval = setInterval(
          async () => await this._handleServerCall({ name, payload }),
          duration
        )
      },

      $signalr_stopServerMethodWithInterval() {
        if (this._signalrInterval) {
          clearInterval(this._signalrInterval)
        }
      },

      async _handleServerCall({ name, payload }) {
        await wireService.callServerMethod({ name, payload })
      }
    }
  }
}
