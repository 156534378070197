import ip from '@/api/ip.api'
import auth from '@/api/auth.api'
import mfa from '@/api/mfa.api'
import tenants from '@/api/tenants.api'
import tenantAddresses from '@/api/tenant-addresses.api'

import users from '@/api/users.api'
import hubs from '@/api/hubs.api'
import hubLocations from '@/api/hub-locations.api'
import geo from '@/api/geo.api'
import uris from '@/api/uris.api'
import rates from '@/api/rates.api'
import teams from '@/api/teams.api'
import pages from '@/api/pages.api'
import sections from '@/api/sections.api'
import items from '@/api/items.api'
import memberNetworks from '@/api/member-networks.api'
import memberNotifications from '@/api/member-notifications.api'
import memberFinder from '@/api/member-finder.api'
import featuredArtists from '@/api/featured-artists.api'
import reports from '@/api/reports.api'
import contracts from '@/api/contracts.api'
import faqs from '@/api/faqs.api'
import kinds from '@/api/kinds.api'
import clients from '@/api/clients.api'
import teamMembers from '@/api/team-members.api'
import teamGroups from '@/api/team-groups.api'
import categories from '@/api/categories.api'
// import attributeCategoryToTenants from '@/api/attributeCategoryToTenants.api'
import dynamicForms from '@/api/dynamic-forms.api'

import userAddress from '@/api/user-address.api'
import userProfile from '@/api/user-profile.api'
import principalFiles from '@/api/principal-files.api'
import submissionFiles from '@/api/submission-files.api'
import showcaseFiles from '@/api/showcase-files.api'

import attributes from '@/api/attributes.api'
import attributeCategories from '@/api/attribute-categories.api'
import principalAttributes from '@/api/principal-attributes.api'
import principalToTenant from '@/api/principal-to-tenant.api'

import healthcareExperience from '@/api/healthcare-experience.api'
import announcements from '@/api/announcements.api'
import assessment from '@/api/assessment/index.api'
import assignments from '@/api/assignments.api'
import assignmentActors from '@/api/assignment-actors.api'
import assignees from '@/api/assignees.api'
import invitees from '@/api/invitees.api'
import invitations from '@/api/invitations.api'
import submissions from '@/api/submissions.api'
import principals from '@/api/principals.api'
import attachments from '@/api/attachments.api'
import professions from '@/api/professions.api'
import medicalSpecialties from '@/api/medical-specialties.api'
import hubComments from '@/api/hub-comments.api'
import userComments from '@/api/user-comments.api'

import machineLearning from '@/api/machine-learning.api'
import userSearches from '@/api/user-searches.api'
import ai from '@/api/ai.api'
import rxNorm from '@/api/rx-norm.api'

export default {
  ...{ ip, auth, mfa, tenants, tenantAddresses },

  users,
  hubs,
  hubLocations,
  geo,
  uris,
  rates,
  teams,
  pages,
  sections,
  items,
  memberNetworks,
  memberNotifications,
  memberFinder,
  featuredArtists,
  reports,
  contracts,
  faqs,
  kinds,
  clients,
  teamMembers,
  teamGroups,
  categories,
  // attributeCategoryToTenants,
  dynamicForms,

  userAddress,
  userProfile,
  principalFiles,
  submissionFiles,
  showcaseFiles,

  attributes,
  attributeCategories,
  principalAttributes,
  principalToTenant,

  healthcareExperience,
  announcements,
  assessment,
  assignments,
  assignmentActors,
  assignees,
  invitees,
  invitations,
  submissions,
  principals,
  attachments,
  professions,
  medicalSpecialties,
  hubComments,
  userComments,

  machineLearning,
  userSearches,
  ai,
  rxNorm
}
