import FileSaver from 'file-saver'

import * as services from '@/services'
import * as notify from '@/utils/notify'

import { CONTRACT_TYPES } from '@/utils/consts'
import { defineAbbr, defineFileNameByResponse } from '@/utils/base'

export default {
  namespaced: true,

  state: {
    principalToContracts: {
      list: null,
      isLoading: false
    }
  },

  getters: {
    definePrincipalToContractsList: (state, _, rootState) => principal => {
      const isSelfUser = principal?.id === rootState.user.user?.id

      const pToCList = isSelfUser
        ? rootState.user.contracts.principalToContracts.list
        : state.principalToContracts.list

      return pToCList
    },

    normalizedContractsByPrincipal: (_, getters, rootState) => principal => {
      const allContracts = rootState.contracts.contracts.list

      if (!allContracts) return null

      const tenantIdList = principal?.tenants?.map(item => item.id) || []

      const filteredByTenantContracts = allContracts.filter(item =>
        tenantIdList.includes(item.tenantId)
      )

      const pToCList = getters.definePrincipalToContractsList(principal)

      if (!pToCList) return null

      const defineLastPtoCbyContractId = contractId => {
        const filteredList = pToCList.filter(
          item => item.contract.id === contractId
        )

        const sortedList = [...filteredList].sort((a, b) => b.id - a.id)

        return sortedList[0]
      }

      return filteredByTenantContracts.map(contract => {
        const lastPrincipalToContract = defineLastPtoCbyContractId(contract.id)

        return { principalToContract: lastPrincipalToContract, ...contract }
      })
    },

    getNormalizedList: (_, getters) => principal => {
      const contracts = getters.normalizedContractsByPrincipal(principal)

      if (!contracts) return null

      return contracts
        .map(({ principalToContract, ...contract }) => {
          const isSigned = Boolean(principalToContract)
          const isRequired = contract.allMembersAreRequiredToSign
          const isLatestVersion =
            contract.versionNumber === principalToContract?.versionNumber

          const label = CONTRACT_TYPES.labelByCode[contract.contractTypeId]

          return {
            isSigned,
            isRequired,
            isLatestVersion,

            contract,
            principalToContract,

            label,
            labelAbbr: defineAbbr(label)
          }
        })
        .sort((a, b) => b.label.localeCompare(a.label))
        .sort((a, b) => a.contract.tenantId - b.contract.tenantId)
    },

    defineIsAllRequiredContractsSigned:
      (_, getters, rootState) => principal => {
        const pToCList = getters.definePrincipalToContractsList(principal)

        if (!pToCList) return null

        principal = principal || rootState.user.user

        const contracts = getters.getNormalizedList(principal)

        if (!contracts) return null

        const isAllRequiredSigned = contracts
          .filter(item => item.isRequired)
          .every(item => item.isSigned && item.isLatestVersion)

        return isAllRequiredSigned
      }
  },

  actions: {
    async FETCH_ALL({ rootState, commit }, principal) {
      principal = principal || rootState.user.user

      await commit('SET_IS_LOADING', true)

      let { list } = await services.contracts.fetchAllForPrincipal(principal)

      const isSelfUser = principal.id === rootState.user.user.id

      await Promise.all(
        [
          isSelfUser && commit('user/contracts/SET_LIST', list, { root: true }),
          commit('SET_LIST', list),
          commit('SET_IS_LOADING', false)
        ].filter(Boolean)
      )

      return list
    },

    async DOWNLOAD_ONE({ commit }, principalToContractId) {
      await commit('SET_IS_LOADING', true)

      const response = await services.contracts.downloadOneForPrincipal(
        principalToContractId
      )

      await commit('SET_IS_LOADING', false)

      const fileName = defineFileNameByResponse(response)

      if (fileName === 'Unknown.dat') {
        notify.error({ text: 'Sorry, the requested file was not found.' })
      } else {
        const url = URL.createObjectURL(new Blob([response?.data]))
        FileSaver.saveAs(url, fileName)
      }

      return response
    }
  },

  mutations: {
    SET_LIST(state, list) {
      state.principalToContracts.list = list
    },
    SET_IS_LOADING(state, isLoading) {
      state.principalToContracts.isLoading = isLoading
    }
  }
}
