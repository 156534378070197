import axios from 'axios'

import {
  formatResponse,
  formatItemResponse,
  formatListResponse
} from '@/utils/api'

const BASE_URL = `/api/item`
const URLs = new Proxy(
  {
    FETCH_ALL: `/get-list`,
    FETCH_ONE_BY_ID: `/get/`,

    UPDATE_ONE: `/update`,
    CREATE_ONE: `/create`,

    FETCH_ALL_BG_IMAGES_BY_ID: `/get-background-images/`,
    UPDATE_ONE_BG_IMAGE: `/change-background-image/`,
    DELETE_ONE_BG_IMAGE: `/remove-background-image`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll() {
    const res = await axios.get(URLs.FETCH_ALL)

    return formatListResponse(res)
  },

  async fetchOneById({ id }) {
    const res = await axios.get(`${URLs.FETCH_ONE_BY_ID}${id}`)

    return formatItemResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(`${URLs.UPDATE_ONE}`, payload)

    return formatItemResponse(res)
  },

  async fetchAllBgImagesById(id) {
    const res = await axios.get(`${URLs.FETCH_ALL_BG_IMAGES_BY_ID}${id}`)

    return formatResponse(res)
  },
  async updateOneBgImage(payload) {
    const res = await axios.post(`${URLs.UPDATE_ONE_BG_IMAGE}`, payload)

    return formatItemResponse(res)
  },
  async deleteOneBgImage(payload) {
    const res = await axios.put(`${URLs.DELETE_ONE_BG_IMAGE}`, payload)

    return formatResponse(res)
  }
}
