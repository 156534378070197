<template>
  <v-sheet class="w-full overflow-hidden rounded" outlined>
    <div
      class="w-full px-4 p-2 m-0 bg-gray-200 rounded-none border-b border-gray-300"
    >
      <div class="gap-4 flex items-center justify-between">
        <div class="flex flex-col text-black">
          <span
            v-if="headerTitle"
            class="font-semibold"
            :class="{ 'text-base ': isHeaderSmall, 'text-lg': isHeaderBig }"
          >
            {{ headerTitle }}
          </span>
          <span v-if="headerSubtitle" :class="{ 'text-sm ': isHeaderSmall }">
            {{ headerSubtitle }}
          </span>
        </div>

        <slot name="header-suffix" />
      </div>
    </div>

    <div
      v-loader="isLoading"
      class="flex shadow-inner overflow-auto"
      :class="contentClass"
    >
      <slot />
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'BaseCard',

  props: {
    headerTitle: { type: String, required: true },
    headerSubtitle: { type: String, default: '' },

    contentClass: { type: String, default: '' },

    isLoading: { type: Boolean, default: false },
    isHeaderBig: { type: Boolean, default: false }
  },

  computed: {
    isHeaderSmall() {
      return !this.isHeaderBig
    }
  }
}
</script>
