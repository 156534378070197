import axios from 'axios'

const BASE_URL = `/api/`
const URLs = new Proxy(
  {
    AUTHENTICATE_LIGHT: `pass/authenticate-light`,
    REQUEST_RESET_BY_EMAIL: `pass/request-reset-by-email`,
    RESET_PASSWORD: `pass/reset`,

    REGISTER_MEMBER_LIGHT: 'sign-up/register-member-light'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async authenticateLight(payload) {
    const res = await axios.post(URLs.AUTHENTICATE_LIGHT, payload)
    const { content: user, isSuccess, error } = res

    return { user, isSuccess, error }
  },

  async requestResetByEmail(payload) {
    const res = await axios.post(URLs.REQUEST_RESET_BY_EMAIL, payload)
    const { isSuccess, error } = res

    return { isSuccess, error }
  },

  async resetPassword(payload) {
    const res = await axios.post(URLs.RESET_PASSWORD, payload)
    const { isSuccess, error } = res

    return { isSuccess, error }
  },

  async registerMemberLight(payload, options) {
    const res = await axios.post(URLs.REGISTER_MEMBER_LIGHT, payload, options)
    const { isSuccess, error } = res

    return { isSuccess, error }
  }
}
