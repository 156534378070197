import axios from 'axios'

import { formatResponse, formatListResponse } from '@/utils/api'

const BASE_URL = `/api/member-notifications`
const URLs = new Proxy(
  {
    FETCH_ALL: `/get-invitees/`,
    FETCH_ALL_BY_USER: `/get/`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll({ userId }) {
    const res = await axios.get(`${URLs.FETCH_ALL}${userId}`)

    return formatListResponse(res)
  },

  async fetchAllByUser({ userId, userTypeCode }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_BY_USER}${userId}/${userTypeCode}`
    )

    return formatResponse(res)
  }
}
