import axios from 'axios'

import {
  formatResponse,
  formatItemResponse,
  formatListResponse
} from '@/utils/api'

const BASE_URL = `/api/category`
const URLs = new Proxy(
  {
    FETCH_ALL: `/get-all`,

    CREATE_ONE: '/create',
    UPDATE_ONE: '/update',
    DELETE_MULTIPLE: '/delete-multiple',

    FETCH_ALL_BY_ITEM_ID: `/get-for-item/`,
    ASSOCIATE_TO_ITEM: '/associate-to-item',
    DELETE_ITEM_ASSOCIATION: '/delete-item-association/'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAll() {
    const res = await axios.get(URLs.FETCH_ALL)

    return formatListResponse(res)
  },
  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatItemResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatItemResponse(res)
  },

  async fetchAllByItemId({ id }) {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_ITEM_ID}${id}`)

    return formatListResponse(res)
  },
  async associateToItem(payload) {
    const res = await axios.post(URLs.ASSOCIATE_TO_ITEM, payload)

    return formatResponse(res)
  },
  async deleteItemAssociation({ itemId, itemToCategoryId }) {
    const res = await axios.delete(
      `${URLs.DELETE_ITEM_ASSOCIATION}${itemId}/${itemToCategoryId}`
    )

    return formatResponse(res)
  }
}
