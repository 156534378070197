<template>
  <div class="h-full p-4 gap-16 flex bg-white">
    <!-- <div>{{ tenants }}</div> -->
  </div>
</template>

<script>
// import { mapState } from 'vuex'

export default {
  name: 'PublicTestPage',

  data: () => ({
    // hello: []
  }),

  computed: {
    // ...mapState('tenants', ['tenants'])
  },

  created() {},

  methods: {}
}
</script>
