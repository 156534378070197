<template>
  <div class="gap-4 flex">
    <v-tooltip
      v-for="(button, index) of buttons"
      :key="`base-${generateUUID()}-btn-${index}`"
      :disabled="!button.tooltip"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-btn
          :data-test="`btn-modal-footer-${index}`"
          class="flex-shrink-1"
          :class="{
            [button.class]: true
          }"
          v-bind="{ ...button.attrs, ...attrs }"
          v-on="on"
          @click="button.handler"
        >
          <v-icon v-if="button.icon" :left="!button.attrs.icon">{{
            button.icon
          }}</v-icon>
          <span v-if="button.text">{{ button.text }}</span>
          <v-icon v-if="button.appendIcon" :right="!button.attrs.icon">{{
            button.appendIcon
          }}</v-icon>
        </v-btn>
      </template>

      <span>{{ button.tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { generateUUID } from '@/utils/base'

export default {
  name: 'BaseButtons',

  props: {
    buttons: { type: Array, default: () => [] }
  },

  created() {
    this.generateUUID = generateUUID
  }
}
</script>
