import axios from 'axios'

import { formatListResponse, formatResponse } from '@/utils/api'

const BASE_URL = `/api/team-member`
const URLs = new Proxy(
  {
    FETCH_ALL_BY_PRINCIPAL: '/get-by-principal/',
    FETCH_VIEWS_BY_TEAM: '/get-views-by-team/',
    FETCH_BY_TEAM_AND_ATTRIBUTE: '/get-by-team-and-attribute/',

    EXPORT_MULTIPLE: '/export',
    DELETE_ONE: '/delete/',
    DELETE_MULTIPLE: '/delete-multiple'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  // userId, userTypeCode
  async fetchAllByPrincipal({ id, typeCode = '1' }) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_BY_PRINCIPAL}${id}/${typeCode}`
    )

    return formatListResponse(res)
  },

  async fetchViewsByTeam(teamId) {
    const res = await axios.get(`${URLs.FETCH_VIEWS_BY_TEAM}${teamId}`)

    return formatListResponse(res)
  },

  async fetchByTeamAndAttribute({ teamId, attributeId }) {
    const res = await axios.get(
      `${URLs.FETCH_BY_TEAM_AND_ATTRIBUTE}${teamId}/${attributeId}`
    )

    return formatListResponse(res)
  },

  async exportMultiple(payload) {
    const res = await axios.post(URLs.EXPORT_MULTIPLE, payload, {
      responseType: 'blob'
    })

    return res
  },

  async deleteOne(id) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatResponse(res)
  },
  async deleteMultiple(payload) {
    const res = await axios.put(URLs.DELETE_MULTIPLE, payload)

    return formatResponse(res)
  }
}
