<template>
  <div id="app-header" class="w-full">
    <AppHeaderPrivate v-if="user" />
    <AppHeaderPublic v-else-if="!isAuthPage && $route.name" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AppHeaderPrivate from '@/components/App/AppHeader/AppHeaderPrivate.vue'
import AppHeaderPublic from '@/components/App/AppHeader/AppHeaderPublic.vue'

export default {
  name: 'AppHeader',

  components: { AppHeaderPrivate, AppHeaderPublic },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('auth', ['isAuthorized']),

    isAuthPage() {
      return this.$isOn.authPage
    }
  }
}
</script>
