// MYZIURA for old Teams components
import catalog from '@/store/modules/admin/teams/catalog'
// registered dynamically in the Teams/TheProfile.vue
// import profile from '@/store/modules/admin/teams/profile'
import ui from '@/store/modules/admin/teams/ui'

export default {
  namespaced: true,

  modules: { catalog, ui },

  state: {
    checkedMembers: [],
    memberFilter: null,
    memberCount: 0,
    listedMemberCount: 0
  },

  getters: {
    getCheckedMembers(state) {
      return state.checkedMembers
    },
    getMemberFilter(state) {
      return state.memberFilter
    },
    getMemberCount(state) {
      return state.memberCount
    },
    getListedMemberCount(state) {
      return state.listedMemberCount
    }
  },

  mutations: {
    setCheckedMembers(state, model) {
      state.checkedMembers = model
    },
    setMemberFilter(state, model) {
      state.memberFilter = model
    },
    setMemberCount(state, model) {
      state.memberCount = model
    },
    setListedMemberCount(state, model) {
      state.listedMemberCount = model
    }
  }
}
