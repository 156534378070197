import axios from 'axios'

import { formatResponse } from '@/utils/api'

const BASE_URL = `/api/page`
const URLs = new Proxy(
  {
    FETCH_ONE_BY_ID: `/get/`,
    FETCH_MEMBER_HOME: `/get-member-home`,
    FETCH_MEMBER_FOOTER: `/get-member-footer`,
    FETCH_ONE_BY_TITLE: '/get-by-title',

    UPDATE_ONE: '/update',
    UPDATE_ITEM_ORDER: '/update-item-order',
    UPDATE_SECTIONS_ORDER: '/update-sections-order',

    DELETE_ITEMS_MULTIPLE: '/delete-items-multiple'
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchOneById({ pageId }) {
    const res = await axios.get(`${URLs.FETCH_ONE_BY_ID}${pageId}`)

    return formatResponse(res)
  },
  async fetchMemberHome() {
    const res = await axios.get(URLs.FETCH_MEMBER_HOME)

    return formatResponse(res)
  },
  async fetchMemberFooter() {
    const res = await axios.get(URLs.FETCH_MEMBER_FOOTER)

    return formatResponse(res)
  },
  async fetchOneByTitle(payload) {
    const res = await axios.post(URLs.FETCH_ONE_BY_TITLE, payload)

    return formatResponse(res)
  },

  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatResponse(res)
  },
  async updateItemOrder(payload) {
    const res = await axios.patch(URLs.UPDATE_ITEM_ORDER, payload)

    return formatResponse(res)
  },
  async updateSectionsOrder(payload) {
    const res = await axios.patch(URLs.UPDATE_SECTIONS_ORDER, payload)

    return formatResponse(res)
  },

  async deleteItemsMultiple(payload) {
    const res = await axios.put(URLs.DELETE_ITEMS_MULTIPLE, payload)

    return formatResponse(res)
  }
}
