export default {
  namespaced: true,

  state: {
    ui: {
      addMembersDialog: { isVisible: false, team: null },
      createSubteamDialog: { isVisible: false, team: null, subteam: null },
      renameTeamDialog: { isVisible: false, team: null },
      deleteTeamDialog: { isVisible: false, team: null },
      deleteSubteamDialog: { isVisible: false, team: null, subteam: null }
    }
  },

  mutations: {
    UPDATE_UI(state, toUpdate) {
      state.ui = { ...state.ui, ...toUpdate }
    }
  }
}
