import { generateConnection } from '@/utils/base'

const SEARCH_TYPES = (() => {
  const CODES = Object.freeze({
    MEMBERS: 2,
    HUBS: 3
  })

  return Object.freeze({
    codes: CODES
  })
})()

const OPERATORS = (() => {
  const NAMES = Object.freeze({
    OR: 'or',
    AND: 'and'
  })

  const CODES = Object.freeze({
    OR: 1,
    AND: 2
  })

  return Object.freeze({
    names: NAMES,
    codes: CODES,

    nameByCode: generateConnection(NAMES, CODES)
  })
})()

const FIELD_KINDS = (() => {
  const NAMES = Object.freeze({
    SINGLELINE: 'single-line',
    SWITCH: 'switch',
    PICKER: 'picker',
    DATE_PICKER: 'date-picker',
    ATTRIBUTE_PICKER: 'attribute-picker',
    CUSTOM: 'custom'
  })

  const VALUES = Object.freeze({
    SINGLELINE: '',
    SWITCH: true,
    PICKER: [],
    DATE_PICKER: '',
    ATTRIBUTE_PICKER: [],
    CUSTOM: null
  })

  const LABELS = Object.freeze({
    SINGLELINE: 'Single Line',
    SWITCH: 'Yes/No',
    PICKER: 'Multiple Picker',
    DATE_PICKER: 'Date Picker',
    ATTRIBUTE_PICKER: 'Attributes Picker',
    CUSTOM: 'Custom'
  })

  const ABBR = Object.freeze({
    SINGLELINE: 'S',
    SWITCH: 'YN',
    PICKER: 'M',
    DATE_PICKER: 'D',
    ATTRIBUTE_PICKER: 'A',
    CUSTOM: 'C'
  })

  return Object.freeze({
    names: NAMES,
    values: VALUES,
    labels: LABELS,
    abbr: ABBR,

    valueByName: generateConnection(VALUES, NAMES),
    labelByName: generateConnection(LABELS, NAMES),
    abbrByName: generateConnection(ABBR, NAMES)
  })
})()

const KEYS = (() => {
  const LONG = Object.freeze({
    NAME: 'name',
    EMAIL: 'email',
    BIO: 'bio',
    BRANDS: 'brands',

    ASSIGNMENTS: 'assignments',
    ASSESSMENTS: 'assessments',
    BEST_WORK_TYPE: 'best-work-type',
    COMPLETED_REGISTRATION_AFTER: 'completed-registration-after',
    COUNTRIES_TERRITORIES: 'countries-territories',
    EXCLUDE_WITHOUT_RESUME: 'exclude-without-resume',
    HAS_SUBMITTED_WORK: 'has-submitted-work',
    HEALTHCARE_PROFESSIONAL: 'healthcare-professional',
    INCLUDE_DELETED: 'include-deleted',
    INVITED_TO_WORK: 'invited-to-work',
    LOGGED_IN_AFTER: 'logged-in-after',
    MEDICAL_PROFESSION: 'medical-profession',
    MEDICAL_SPECIALTY: 'medical-specialty',
    NDA_SIGNED: 'nda-signed',
    CA_SIGNED: 'ca-signed',
    NEVER_LOGGED_IN: 'never-logged-in',
    PRE_QUALIFIED: 'pre-qualified',
    RATED_AFTER: 'rated-after',
    RATING: 'rating',
    RATES: 'rates',
    STATES_PROVINCES: 'states-provinces',
    TEAM: 'team',
    USER_STATUS: 'user-status',
    VETTED: 'vetted'
  })

  const SHORT = Object.freeze({
    ASSIGNMENTS: 'j',
    ASSESSMENTS: 'a',
    BEST_WORK_TYPE: 'bw',
    BIO: 'b',
    BRANDS: 'br',
    COMPLETED_REGISTRATION_AFTER: 'cra',
    COUNTRIES_TERRITORIES: 'ct',
    EMAIL: 'e',
    EXCLUDE_WITHOUT_RESUME: 'ewr',
    HAS_SUBMITTED_WORK: 'sw',
    HEALTHCARE_PROFESSIONAL: 'hp',
    INCLUDE_DELETED: 'id',
    INVITED_TO_WORK: 'itw',
    LOGGED_IN_AFTER: 'la',
    MEDICAL_PROFESSION: 'mp',
    MEDICAL_SPECIALTY: 'ms',
    NDA_SIGNED: 'ns',
    CA_SIGNED: 'cs',
    NAME: 'n',
    NEVER_LOGGED_IN: 'nl',
    PRE_QUALIFIED: 'pq',
    RATED_AFTER: 'rt',
    RATING: 'rt',
    RATES: 'r',
    STATES_PROVINCES: 'sp',
    TEAM: 't',
    USER_STATUS: 'us',
    VETTED: 'v'
  })

  const LABELS = Object.freeze({
    ASSIGNMENTS: 'Assignments',
    ASSESSMENTS: 'Assessments',
    BEST_WORK_TYPE: 'Filter to best',
    BIO: 'Bio',
    BRANDS: 'Brands',
    COUNTRIES_TERRITORIES: 'Countries & Territories',
    COMPLETED_REGISTRATION_AFTER: 'Completed Registration After',
    EMAIL: 'Email',
    EXCLUDE_WITHOUT_RESUME: 'Exclude w/out resume link or file',
    HAS_SUBMITTED_WORK: 'Has Participated',
    HEALTHCARE_PROFESSIONAL: 'Healthcare Professional',
    INCLUDE_DELETED: 'Include Deleted',
    INVITED_TO_WORK: 'Was Invited',
    LOGGED_IN_AFTER: 'Logged in After',
    MEDICAL_PROFESSION: 'Medical Profession',
    MEDICAL_SPECIALTY: 'Medical Specialty',
    NAME: 'Name',
    NDA_SIGNED: 'NDA Signed',
    CA_SIGNED: 'CA Signed',
    NEVER_LOGGED_IN: 'Never Logged In',
    PRE_QUALIFIED: 'Pre-Qualified',
    RATED_AFTER: 'Rated After',
    RATING: 'Rating',
    RATES: 'Rate',
    STATES_PROVINCES: 'States / Provinces',
    TEAM: 'Team',
    USER_STATUS: 'Status',
    VETTED: 'Vetted'
  })

  const KINDS = Object.freeze({
    ASSESSMENTS: FIELD_KINDS.names.CUSTOM,
    ASSIGNMENTS: FIELD_KINDS.names.PICKER,
    BEST_WORK_TYPE: FIELD_KINDS.names.SWITCH,
    BIO: FIELD_KINDS.names.SINGLELINE,
    BRANDS: FIELD_KINDS.names.SINGLELINE,
    COUNTRIES_TERRITORIES: FIELD_KINDS.names.PICKER,
    COMPLETED_REGISTRATION_AFTER: FIELD_KINDS.names.DATE_PICKER,
    EMAIL: FIELD_KINDS.names.SINGLELINE,
    EXCLUDE_WITHOUT_RESUME: FIELD_KINDS.names.SWITCH,
    HAS_SUBMITTED_WORK: FIELD_KINDS.names.SWITCH,
    HEALTHCARE_PROFESSIONAL: FIELD_KINDS.names.SWITCH,
    INCLUDE_DELETED: FIELD_KINDS.names.SWITCH,
    INVITED_TO_WORK: FIELD_KINDS.names.SWITCH,
    LOGGED_IN_AFTER: FIELD_KINDS.names.DATE_PICKER,
    MEDICAL_PROFESSION: FIELD_KINDS.names.PICKER,
    MEDICAL_SPECIALTY: FIELD_KINDS.names.PICKER,
    NAME: FIELD_KINDS.names.SINGLELINE,
    NDA_SIGNED: FIELD_KINDS.names.SWITCH,
    CA_SIGNED: FIELD_KINDS.names.SWITCH,
    NEVER_LOGGED_IN: FIELD_KINDS.names.SWITCH,
    PRE_QUALIFIED: FIELD_KINDS.names.SWITCH,
    RATED_AFTER: FIELD_KINDS.names.DATE_PICKER,
    RATES: FIELD_KINDS.names.CUSTOM,
    RATING: FIELD_KINDS.names.PICKER,
    STATES_PROVINCES: FIELD_KINDS.names.PICKER,
    TEAM: FIELD_KINDS.names.PICKER,
    USER_STATUS: FIELD_KINDS.names.PICKER,
    VETTED: FIELD_KINDS.names.SWITCH
  })

  const MESSAGES = Object.freeze({
    ASSESSMENTS: 'Restricts member results to AND',
    COUNTRIES_TERRITORIES: 'Restricts hub results to AND',
    EXCLUDE_WITHOUT_RESUME: 'Restricts member results to AND',
    STATES_PROVINCES: 'Restricts hub results to AND',
    MEDICAL_SPECIALTY: 'Restricts member results to AND',
    RATES: 'Restricts member results to AND',
    TEAM: 'Restricts member results to AND'
  })

  return Object.freeze({
    long: LONG,
    short: SHORT,
    labels: LABELS,
    kinds: KINDS,
    messages: MESSAGES,

    longByShort: generateConnection(LONG, SHORT),
    shortByLong: generateConnection(SHORT, LONG),

    labelByShort: generateConnection(LABELS, SHORT),
    labelByLong: generateConnection(LABELS, LONG),

    kindByShort: generateConnection(KINDS, SHORT),
    kindByLong: generateConnection(KINDS, LONG),

    messageByLong: generateConnection(MESSAGES, LONG)
  })
})()

const BASE_POSSIBLE_FIELDS = Object.values(KEYS.long).reduce((result, key) => {
  const kind = KEYS.kindByLong[key]

  result[key] = {
    key,
    kind,
    label: KEYS.labelByLong[key],
    value: FIELD_KINDS.valueByName[kind],
    message: KEYS.messageByLong[key] || ''
  }

  return result
}, {})

export { OPERATORS, FIELD_KINDS, SEARCH_TYPES, KEYS, BASE_POSSIBLE_FIELDS }
